<template>
    <v-card
            class="mx-auto my-8"
    >
        <v-avatar style="position: absolute; right: 40px">
            <v-img src="../assets/images/champion.png"></v-img>
        </v-avatar>
        <v-avatar style="position: absolute; left: -25px; top: -25px">
            <v-img src="../assets/images/balloons.png"></v-img>
        </v-avatar>
        <v-card-title>
            <v-row class="text-left">
                <v-col cols="12">
                    <v-btn
                            outlined
                            small
                            color="indigo"
                    >
                        +35
                    </v-btn>
                    <v-btn
                            class="ml-3"
                            dark
                            small
                            color="#bc6daf"
                    >
                        Good Job
                    </v-btn>
                    <span class="float-right"><v-icon>mdi-dots-vertical</v-icon></span>
                </v-col>
            </v-row>

        </v-card-title>
        <!--                <v-icon>mdi-chevron-right</v-icon>-->
        <v-card-text>
            <v-subheader
                    class="pl-0"
            >
                <v-icon small>mdi-timer-sand</v-icon>
                4 months ago
            </v-subheader>


            <div class="subtitle-1 text-left">
                <v-avatar size="35">
                    <v-img src="../assets/images/confetti.png"></v-img>
                </v-avatar>
                <span style="color: #bc6daf; font-weight: bold"> Ali Alhajjow:</span> <span style="color: rgb(113,166,208)"> +15 </span> <v-icon color="#bc6daf" style="font-weight: bolder; font-size: 220%">mdi-chevron-right</v-icon><v-icon class="ml-n6" style="font-weight: bolder; font-size: 220%">mdi-chevron-right</v-icon> <span style="color: black">Anas and 3 others</span>
            </div>

            <div class="text-left">Small plates, salads & sandwiches - an intimate setting with 12 indoor seats plus patio seating.</div>
            <div class="text-left" style="color: rgb(113,166,208)">#Birthday #Celebrate @ahmad</div>
        </v-card-text>

        <div style="position: relative; width: 100%">
            <v-btn
                    absolute
                    style="right: -20px; top: -50px"
                    fab
                    small
                    color="#f8f8f8"
            >
                <v-icon dark>
                    mdi-thumb-up-outline
                </v-icon>
            </v-btn>
        </div>



        <v-divider class="mx-4"></v-divider>
        <div style="position: relative; width: 100%">
            <v-btn
                    absolute
                    style="right: -20px; top: 10px"
                    fab
                    small
                    color="#f8f8f8"
            >
                <v-icon dark>
                    mdi-comment-text-outline
                </v-icon>
            </v-btn>
        </div>
        <v-card-subtitle class="text-left">
            <v-avatar size="35">
                <v-img src="../assets/images/confetti.png"></v-img>
            </v-avatar>
            <b> John</b> and 17 others liked this post <span class="float-right pt-1 pr-5">2 comments</span>
        </v-card-subtitle>
        <v-card-text class="text-left d-inline-flex" style="background-color: #f6f6f6">
            <v-avatar size="35" class="mt-3 mr-3">
                <v-img src="../assets/images/confetti.png"></v-img>
            </v-avatar>
            <v-text-field
                    style="color: #a1a1a1"
                    label="Write a comment"
                    hide-details="auto"
            ></v-text-field>
        </v-card-text>
        <v-card-text style="background-color: #f6f6f6" class="text-left">
            <v-list-item class="pl-0">
                <v-list-item-avatar size="35">
                    <v-img src="../assets/images/confetti.png"></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-title>
                        <b style="color: #bc6daf">Anas Ibraheem: </b> Happy Birthday
                        <span class="float-right"><v-icon>mdi-dots-horizontal</v-icon></span>
                    </v-list-item-title>
                    <v-list-item-subtitle>
                        <v-icon small>mdi-timer-sand</v-icon>
                        4 days ago
                        <span class="pl-5"><b style="color: rgb(113,166,208)">Liked</b> 3</span>
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-card-text>
    </v-card>
</template>

<script>
    export default {
        name : 'post'
    }
</script>


<style scoped>

</style>