<template>
  <v-row class="pl-lg-16 pr-lg-16 mr-3 ml-3">
    <v-col lg="6" sm="12">
      <v-row>
        <v-col cols="12">
          <v-btn class="float-left mt-5 mr-5"
                 color="#fff"
                 rounded
          >All
          </v-btn>
          <v-btn class="float-left mt-5 mr-5"
                 color="#fff"
                 rounded
          >Posts
          </v-btn>
          <v-btn class="float-left mt-5"
                 color="#fff"
                 rounded
          >People
          </v-btn>
        </v-col>
      </v-row>
      
      <people/>
      <!--            <v-badge color="#fff"-->
      <!--                     class="float-right mr-16 mt-4"-->
      <!--            >-->
      <!--                <template v-slot:badge>-->
      <!--                    <v-avatar>-->
      <!--                        <v-img src="../assets/images/champion.png"></v-img>-->
      <!--                    </v-avatar>-->
      <!--                </template>-->
      
      <!--            </v-badge>-->
      
      <post/>
      
      <v-card
              :loading="loading"
              class="mx-auto my-8"
      >
        <template slot="progress">
          <v-progress-linear
                  color="deep-purple"
                  height="10"
                  indeterminate
          ></v-progress-linear>
        </template>
        
        <v-img
                height="250"
                src="https://cdn.vuetifyjs.com/images/cards/cooking.png"
        ></v-img>
        
        <v-card-title>Cafe Badilico</v-card-title>
        
        <v-card-text>
          <v-row
                  align="center"
                  class="mx-0"
          >
            <v-rating
                    :value="4.5"
                    color="amber"
                    dense
                    half-increments
                    readonly
                    size="14"
            ></v-rating>
            
            <div class="grey--text ml-4">
              4.5 (413)
            </div>
          </v-row>
          
          <div class="my-4 subtitle-1">
            $ • Italian, Cafe
          </div>
          
          <div>Small plates, salads & sandwiches - an intimate setting with 12 indoor seats plus patio
            seating.
          </div>
        </v-card-text>
        
        <v-divider class="mx-4"></v-divider>
        
        <v-card-title>Tonight's availability</v-card-title>
        
        <v-card-text>
          <v-chip-group
                  active-class="deep-purple accent-4 white--text"
                  column
                  v-model="selection"
          >
            <v-chip>5:30PM</v-chip>
            
            <v-chip>7:30PM</v-chip>
            
            <v-chip>8:00PM</v-chip>
            
            <v-chip>9:00PM</v-chip>
          </v-chip-group>
        </v-card-text>
        
        <v-card-actions>
          <v-btn
                  @click="reserve"
                  color="deep-purple lighten-2"
                  text
          >
            Reserve
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
    <side-stats/>
  </v-row>

</template>


<script>
    import post from '../components/post';
    import people from "../components/people";
    import SideStats from "../components/side_stats";

    export default {
        data: () => ({}),

        components: {
            SideStats,
            people,
            post
        }

    }

</script>

<style scoped>
  .text-field {
    color: #90C143 !important;
  }
</style>
