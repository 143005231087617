<template>
  <v-col class="hidden-sm-and-down" cols="6">
    <v-row>
      <v-col cols="6">
        <v-card
                class="mx-auto my-8 pt-5 text-left"
                color="#bc6daf"
                outlined
        >
          <v-icon class="ml-5"
                  dark
                  large
          >
            mdi-cake-layered
          </v-icon>
          <br>
          <v-avatar class="ml-5 mt-3"
                    dark
                    size="65"
                    width="65"
          >
            <v-img src="https://mybusinesscounsel.com/img/placeholder.png" width="40"></v-img>
          </v-avatar>
          <v-list-item class="mb-5" dark two-line>
            <v-list-item-content>
              <v-list-item-title class="headline" style="white-space: unset;">
                Today is Anas's Birthday
              </v-list-item-title>
              <v-list-item-subtitle style="white-space: unset;">Greyhound divisely hello coldly
                fonwderfully
              </v-list-item-subtitle>
            </v-list-item-content>
          
          </v-list-item>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card
                class="mx-auto my-8 pt-3 text-center"
                color="#7d5ac2"
                outlined
        >
          <v-list-item class="pa-3" dark>
            <v-list-item-content>
              <v-list-item-title class="headline" style="white-space: unset;">
                You have 100 points to give
              </v-list-item-title>
            </v-list-item-content>
          
          </v-list-item>
        </v-card>
        <v-card
                class="mx-auto my-8 pt-3 text-center"
                color="#7d5ac2"
                outlined
        >
          <v-list-item class="pa-3" dark>
            <v-list-item-content>
              <v-list-item-title class="headline" style="white-space: unset;">
                You have 100 points to redeem
              </v-list-item-title>
            </v-list-item-content>
          
          </v-list-item>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card
                class="mx-auto my-n8 pt-5 text-left"
                color="#3fdfc4"
                dark
                outlined
        >
          <v-card-title class="headline">
            Announcements
          </v-card-title>
          <v-divider dark></v-divider>
          <v-card-subtitle class="pt-16 pb-10">Listen to your favorite artists and albums whenever and
            wherever, online and offline.
          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card
                class="mx-auto my-n8 text-left font-weight-bold"
                outlined
        
        >
          <v-card-subtitle>
            Top Rewards
          </v-card-subtitle>
          <v-row>
            <v-col cols="6">
              <v-img class="mx-3"
                     src="../assets/images/balloons.png"
              ></v-img>
            </v-col>
            <v-col cols="6">
              <v-img class="mx-3"
                     src="../assets/images/confetti.png"
              ></v-img>
            </v-col>
            <v-col cols="6">
              <v-img class="mx-3"
                     src="../assets/images/fireworks.png"
              ></v-img>
            </v-col>
            <v-col cols="6">
              <v-img class="mx-3"
                     src="../assets/images/like.png"
              ></v-img>
            </v-col>
          </v-row>
        
        </v-card>
      </v-col>
    </v-row>
  
  </v-col>
</template>

<script>
    export default {
        name: 'side-stats'
    }
</script>