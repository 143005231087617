<template>
    <v-app id="app">

        <div v-if="this.$router.history.current.path == '/login' ">
            <router-view></router-view>
        </div>

        <div v-else>
        <v-navigation-drawer
                permanent
                expand-on-hover
                app
        >
            <v-list>
                <v-list-item class="px-2">
                    <v-list-item-avatar rounded>
                        <v-img src="./assets/wee-logo.png"></v-img>
                    </v-list-item-avatar>
                </v-list-item>


            </v-list>

            <v-divider></v-divider>

            <v-list class="mt-16"
                    nav
                    dense
            >
                <v-list-item link>
                    <v-list-item-icon>
                        <v-icon>mdi-home-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Home</v-list-item-title>
                </v-list-item>
                <v-list-item link>
                    <v-list-item-icon>
                        <v-icon>mdi-account-circle-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Profile</v-list-item-title>
                </v-list-item>
                <v-list-item link>
                    <v-list-item-icon>
                        <v-icon>mdi-gift-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Gift Shop</v-list-item-title>
                </v-list-item>
                <v-list-item link>
                    <v-list-item-icon>
                        <v-icon>mdi-cogs</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Settings</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
        <v-app-bar color="#3f4156" dark app>
            <v-row class="pt-6">
                <v-col cols="6">
                    <v-text-field
                            label="Search"
                            prepend-icon="mdi-search"
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="6">
                    <div class="text-right">
                        <v-menu
                                open-on-hover
                                down
                                offset-y
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                        class="mr-5"
                                        color="darken-2"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                >
                                    mdi-bell-ring-outline
                                </v-icon>
                            </template>

                            <v-list>
                                <v-list-item>
                                    <v-list-item-title>Profile</v-list-item-title>
                                    <v-list-item-title>Logout</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                        <v-menu
                                open-on-hover
                                down
                                offset-y
                        >
                            <template v-slot:activator="{ on, attrs }">

                                <v-list-item two-line class="mt-n6 d-inline-flex text-left" style="width: 140px">
                                    <v-list-item-content>
                                        <v-list-item-title>Ali Alhajjow</v-list-item-title>
                                        <v-list-item-subtitle>Web Developer</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-avatar
                                        color="primary"
                                        size="40"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                >
                                    <v-img src="https://mybusinesscounsel.com/img/placeholder.png"></v-img>
                                </v-avatar>

                            </template>

                            <v-list>
                                <v-list-item>
                                    <v-list-item-title>Profile</v-list-item-title>
                                    <v-list-item-title>Logout</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                </v-col>
            </v-row>
        </v-app-bar>
        <v-content style="background-color: #f1f1f1; min-height:100vh">
            <v-container fluid>
                <v-row class="fill-height">
                    <v-col>
                        <transition name="fade">
                            <router-view></router-view>
                        </transition>
                    </v-col>
                </v-row>
            </v-container>
        </v-content>

        <v-footer app>
            @copyrights WE 2021
        </v-footer>
        </div>
    </v-app>
</template>

<style>
    #app {
        text-align: center;
    }
</style>
<script>

    export default {
        data: () => ({
            drawer: false,
            group: null,
        }),

        watch: {
            group () {
                this.drawer = false
            },
        },
        computed: {
            currentRouteName() {
                return this.$route.name;
            }
        }
    }
</script>