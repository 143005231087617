import Vue from "vue";
import VueRouter from "vue-router";
import homepage from "../views/home.vue";
import profile from "../views/profile.vue";
import notifications from "../views/notifications";
import settings from "../views/settings";
import gift_shop from "../views/gift_shop";
import login from "../views/login";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: homepage
  },
  {
    path: "/profile",
    component: profile
  },
  {
    path: "/notifications",
    component: notifications
  },
  {
    path: "/settings",
    component: settings
  },
  {
    path: "/gift-shop",
    component: gift_shop
  },
  {
    path: "/login",
    component: login
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});
export default router;
