<template>
  <v-row>
    <v-col offset-md="4" offset-sm="0" sm="12" class="pt-md-16 pt-0 mt-10 mt-md-16 px-12" md="4">
      <v-card class="text-center px-8" outlined>
        <v-avatar rounded size="60" class="mt-5">
          <v-img src="../assets/wee-logo.png"></v-img>
        </v-avatar>
        
        <v-card-title class="justify-center">LOGIN</v-card-title>
        
        <v-card-text>
          <v-text-field append-icon="mdi-user" label="Username"></v-text-field>
          <v-text-field label="Password"></v-text-field>
          <v-btn
                  dark
                  color="#A53594"
                  elevation="2"
                  style="width: 100%"
                  rounded
                  class="mt-5"
          >Login</v-btn>
        </v-card-text>
        <v-card-subtitle class="justify-center" style="color: #A53594">
          Forgot Password?
        </v-card-subtitle>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>

    export default {
        data: () => ({}),

        components: {
        
        }

    }

</script>

<style>
  #app {
    background: url('../assets/login-bg.png') no-repeat center center fixed !important;
    background-size: cover;
  }
</style>
