<template>
  <v-row class="pl-lg-16 pr-lg-16 mr-3 ml-3">
    <v-col lg="6" sm="12">
      <v-card
              class="mx-auto my-8"
      >
        <v-card-title style="color: #bc6daf">Notifications</v-card-title>
        <v-list-item class="text-left new">
          <v-list-item-avatar>
            <v-img src="../assets/images/balloons.png"></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>Ahmad commented on your post</v-list-item-title>
            <v-list-item-subtitle><v-icon>mdi-timer-sand</v-icon> 4 mins ago</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item class="text-left">
          <v-list-item-avatar>
            <v-img src="../assets/images/balloons.png"></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>Ahmad commented on your post</v-list-item-title>
            <v-list-item-subtitle><v-icon>mdi-timer-sand</v-icon> 8 mins ago</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </v-col>
    <side-stats/>
  </v-row>

</template>


<script>

    import SideStats from "../components/side_stats";
    export default {
        data: () => ({}),

        components: {
            SideStats
        }

    }

</script>

<style scoped>
  .text-field {
    color: #90C143 !important;
  }
  
  .new {
    background-color: rgb(239, 218, 236);
  }
</style>
