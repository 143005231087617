<template>
  <v-row class="pl-lg-16 pr-lg-16 mr-3 ml-3">
    <v-col lg="6" sm="12">
      <v-card
              class="mx-auto my-8"
      >
        <v-card-title style="color: #bc6daf">Settings</v-card-title>
        <v-expansion-panels accordion>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <span><v-icon>mdi-bell-outline</v-icon> Notifications</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-list-item class="text-left pl-7">
                <v-list-item-content>
                  <v-list-item-title>Post Notifications</v-list-item-title>
                  <v-list-item-subtitle style="white-space: unset;">these notifications when someone tag you in his post</v-list-item-subtitle>
                </v-list-item-content>
                <v-switch></v-switch>
              </v-list-item>
              <v-list-item class="text-left pl-7">
                <v-list-item-content>
                  <v-list-item-title>Like Notifications</v-list-item-title>
                  <v-list-item-subtitle style="white-space: unset;">these notifications when someone like your post or comment or post you have been tagged in</v-list-item-subtitle>
                </v-list-item-content>
                <v-switch></v-switch>
              </v-list-item>
              
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <span><v-icon>mdi-google-translate</v-icon> Language</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-radio-group class="text-left pl-7">
                <v-radio
                        label="Arabic"
                        value="ar"
                >
                </v-radio>
                <v-radio
                        label="English"
                        value="en"
                >
                </v-radio>
              </v-radio-group>
    
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-col>
    <side-stats/>
  </v-row>

</template>


<script>

    import SideStats from "../components/side_stats";
    export default {
        data: () => ({}),

        components: {
            SideStats
        }

    }

</script>

<style scoped>
  .text-field {
    color: #90C143 !important;
  }
  
  .new {
    background-color: rgb(239, 218, 236);
  }
</style>
