<template>
  <v-card
          class="mx-auto my-8"
  >
    <v-card-title style="color: #bc6daf">People</v-card-title>
    <v-list-item class="text-left">
      <v-list-item-avatar>
        <v-img src="../assets/images/balloons.png"></v-img>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>Anas Ibraheem</v-list-item-title>
        <v-list-item-subtitle>Facility of computer engineering in Damascus university
        </v-list-item-subtitle>
        <v-list-item-subtitle style="color: #bc6daf">Backend Developer</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-list-item class="text-left">
      <v-list-item-avatar>
        <v-img src="../assets/images/confetti.png"></v-img>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>Ali Alhajjow</v-list-item-title>
        <v-list-item-subtitle>Facility of computer programming in Homs university</v-list-item-subtitle>
        <v-list-item-subtitle style="color: #bc6daf">Web Developer</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-list-item class="text-left">
      <v-list-item-avatar>
        <v-img src="../assets/images/like.png"></v-img>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>Abdullah Alsaleh</v-list-item-title>
        <v-list-item-subtitle>Facility of IT engineering in Aleppo university</v-list-item-subtitle>
        <v-list-item-subtitle style="color: #bc6daf">Project Manager</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>

<script>
  export default {
    name: 'people'
  }
</script>


<style scoped>

</style>