<template>
  <v-row class="pl-lg-4 pr-lg-4 mr-3 ml-3">
    <v-col lg="6" sm="12">
      <v-card class="mx-auto my-8">
        <v-tabs color="deep-purple accent-4" grow>
          <v-tab>Charities</v-tab>
          <v-tab>Gifts</v-tab>
          <v-tab>Orders</v-tab>
    
          <v-tab-item
                  key="1"
          >
            <v-container fluid>
              <v-row>
                <v-col
                        v-for="i in 6"
                        :key="i"
                        cols="12"
                        md="4"
                >
                  <v-img
                          :src="`https://picsum.photos/500/300?image=${i * 5 + 10}`"
                          :lazy-src="`https://picsum.photos/10/6?image=${i * 5 + 10}`"
                          aspect-ratio="1"
                  ><div class="image-title py-2">Charity Name</div></v-img>
                  
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
          <v-tab-item
                  key="2"
          >
            <v-tabs color="deep-purple accent-4" grow>
              <v-tab>All Categories</v-tab>
              <v-tab>Category 1</v-tab>
              <v-tab>Category 2</v-tab>
              <v-tab>Category 3</v-tab>
              <v-tab>Category 4</v-tab>
    
              <v-tab-item
                      key="1"
              >
                <v-container fluid>
                  <v-row>
                    <v-col
                            v-for="i in 6"
                            :key="i"
                            cols="12"
                            md="4"
                    >
                      <v-img
                              :src="`https://picsum.photos/500/300?image=${i * 5 + 10}`"
                              :lazy-src="`https://picsum.photos/10/6?image=${i * 5 + 10}`"
                              aspect-ratio="1"
                      ><div class="image-title py-2">Charity Name</div></v-img>
          
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
              <v-tab-item
                      key="2"
              >
                <v-container fluid>
                  <v-row>
                    <v-col
                            v-for="i in 6"
                            :key="i"
                            cols="12"
                            md="4"
                    >
                      <v-img
                              :src="`https://picsum.photos/500/300?image=${i * 5 + 10}`"
                              :lazy-src="`https://picsum.photos/10/6?image=${i * 5 + 10}`"
                              aspect-ratio="1"
                      ><div class="image-title py-2">Gift Name</div></v-img>
          
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
              <v-tab-item
                      key="3"
              >
                <v-container fluid>
                  <v-row>
        
                  </v-row>
                </v-container>
              </v-tab-item>
              <v-tab-item
                      key="4"
              >
                <v-container fluid>
                  <v-row>
        
                  </v-row>
                </v-container>
              </v-tab-item>
              <v-tab-item
                      key="5"
              >
                <v-container fluid>
                  <v-row>
        
                  </v-row>
                </v-container>
              </v-tab-item>
            </v-tabs>
          </v-tab-item>
          <v-tab-item
                  key="3"
          >
            <v-container fluid>
              <v-row>
                <v-tabs color="deep-purple accent-4" grow>
                  <v-tab>All</v-tab>
                  <v-tab>Pending</v-tab>
                  <v-tab>Received</v-tab>
                  <v-tab>Rejected</v-tab>
                  
                  <v-tab-item key="1">
                    <v-container fluid>
                      <v-row>
                        <v-col
                                cols="12"
                                md="4"
                        >
                          <v-img
                                  :src="`https://picsum.photos/500/300?image=${5 + 10}`"
                                  :lazy-src="`https://picsum.photos/10/6?image=${5 + 10}`"
                                  aspect-ratio="1"
                          >
                            <div class="image-status received">
                              <v-icon dark>mdi-bookmark-check</v-icon>
                            </div>
                            <div class="image-title py-2">Order
                              <span class="text-right float-right pr-2">+30</span>
                              <br>
                              <div class="text-right pr-2" style="width: 100%; font-size: 80%">10/02/2020</div>
                            </div>
                            
                          </v-img>
      
                        </v-col>
                        <v-col
                                cols="12"
                                md="4"
                        >
                          <v-img
                                  :src="`https://picsum.photos/500/300?image=${20}`"
                                  :lazy-src="`https://picsum.photos/10/6?image=${20}`"
                                  aspect-ratio="1"
                          >
                            <div class="image-status pa-auto pending">
                              <v-icon dark>mdi-calendar-clock</v-icon>
                            </div>
                            <div class="image-title py-2">Order
                              <span class="text-right float-right pr-2">+30</span>
                              <br>
                              <div class="text-right pr-2" style="width: 100%; font-size: 80%">10/02/2020</div>
                            </div>
                            
                          </v-img>
      
                        </v-col>
                        <v-col
                                cols="12"
                                md="4"
                        >
                          <v-img
                                  :src="`https://picsum.photos/500/300?image=${30}`"
                                  :lazy-src="`https://picsum.photos/10/6?image=${30}`"
                                  aspect-ratio="1"
                          >
                            <div class="image-status pa-auto rejected">
                              <v-icon dark>mdi-bookmark-remove</v-icon>
                            </div>
                            <div class="image-title py-2">Order
                              <span class="text-right float-right pr-2">+30</span>
                              <br>
                              <div class="text-right pr-2" style="width: 100%; font-size: 80%">10/02/2020</div>
                            </div>
                            
                          </v-img>
      
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-tab-item>
                </v-tabs>
              </v-row>
            </v-container>
          </v-tab-item>
        </v-tabs>
      </v-card>
    </v-col>
    <side-stats/>
  </v-row>

</template>


<script>

    import SideStats from "../components/side_stats";
    export default {
        data: () => ({}),

        components: {
            SideStats
        }

    }

</script>

<style scoped>
  .text-field {
    color: #90C143 !important;
  }
  
  .image-title {
    position: absolute;
    bottom: 0;
    left: 0;
    text-align: left;
    padding-left: 5px;
    font-size: 85%;
    background-color: rgb(188, 109, 175); width: 100%;
    color: #fff
  }
  
  .image-status {
    position: absolute;
    top: 15px;
    right: 20px;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    padding-top: 5px
  }
  
  .pending {
    background-color: gray;
  }
  
  .received {
    background-color: #38A9FF;
  }
  
  .rejected {
    background-color: #DA2128;
  }
  
  .new {
    background-color: rgb(239, 218, 236);
  }
</style>
