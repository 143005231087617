<template>
  <v-row>
    <v-banner
            :sticky="sticky"
            class="pa-0"
            single-line
            style="width: 100%"
    >
      <div style="max-height: 350px">
        <v-img
                src="../assets/images/cover.jpg"
        ></v-img>
        <div style="height: 50px; width: 100%; background-color: #000; position: absolute; bottom: 0; opacity: .4"></div>
        <v-avatar size="150"
                  style="position: absolute; left: 100px; bottom: 0; background-color: #fff; border: 3px solid #fff">
          <v-img src="../assets/users/ali.jpg"
          ></v-img>
        </v-avatar>
        <div class="pt-3" style="height: 50px; width: 100%; position: absolute; bottom: 0;">
          <span style="position: absolute; left: 300px; color: #fff; font-weight: bolder">Received</span>
          <span style="position: absolute; left: 420px; color: #fff; font-weight: bolder">Given</span>
        </div>
      </div>
    </v-banner>
  </v-row>
</template>

<script>
    export default {
        name : 'profile-header'
    }
</script>